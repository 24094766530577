import { createRouter, createWebHistory } from 'vue-router';
import ListDetails from './components/ListDetails.vue';
// import SpotDetails from './components/SpotDetails.vue';

const routes = [
  {
    path: '/list/:id',
    name: 'list-details',
    component: ListDetails,
  }
  //,
  // {
  //   path: '/spot/:place_id',
  //   name: 'SpotDetails',
  //   component: SpotDetails,
  //   props: true
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;