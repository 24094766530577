<template>
  <div class="outer-container" :style="outerContainerStyle">
    <div class="container" :style="containerStyle">
      <div class="header" :style="headerStyle">
        <div class="thumbnail-wrapper" :style="thumbnailWrapperStyle">
          <img v-if="thumbnailUrl" class="thumbnail-blur" :src="thumbnailUrl" alt="Thumbnail Background" :style="thumbnailBlurStyle">
          <img v-if="thumbnailUrl" class="thumbnail-center" :src="thumbnailUrl" alt="Thumbnail">
        </div>
      </div>
      <div class="title">
        <h1>{{ list?.name || "Niki List" }}</h1>
      </div>
      <div v-if="profile" class="profile">
        <div class="profile-photo-container">
          <div class="profile-photo-mask">
            <img :src="profile.photo_url || 'default-avatar.png'" alt="Profile" class="profile-photo">
          </div>
          <img :src="profileMaskBgUrl" alt="Profile Background" class="profile-photo-bg">
        </div>
        <div class="profile-details">
          <div class="profile-name" :class="{ placeholder: !profile }">{{ profile?.name || '' }}</div>
          <div class="profile-handle" :class="{ placeholder: !profile }">@{{ profile?.handle || '' }}</div>
        </div>
      </div>
      <div v-if="places.length">
        <div v-for="(place, index) in places" :key="index" class="item">
          <img :src="place.photoUrl || 'default-placeholder.png'" :alt="place.name">
          <div class="item-content">
            <h3>{{ place.name }} <span class="rating">★ {{ place.rating }}</span></h3>
            <p>{{ place.address }}</p>
            <div class="tags">
              <div v-if="place.subtype" class="tag">{{ place.subtype }}</div>
              <div v-if="place.neighborhood" class="tag">{{ place.neighborhood }}</div>
              <div v-if="place.priceLevel" class="tag">{{ priceLevelToString(place.priceLevel) }}</div>
            </div>
          </div>
          <hr class="divider" v-if="index < places.length - 1">
        </div>
      </div>
      <div v-else>
        <p>Loading...</p>
      </div>
    </div>
    <div class="bottom-overlay" :class="{ 'slide-up': showOverlay }">
      <div class="overlay-content">
        <div class="overlay-header">
          <button @click="dismissOverlay" class="overlay-close">&times;</button>
        </div>
        <div class="overlay-body">
          <img :src="appIconUrl" class="overlay-profile-photo">
          <p class="overlay-title">See full list in the app</p>
          <p class="overlay-subtitle">Enjoy recommendations, reviews and more from @{{ profile?.handle || '' }}</p>
          <a :href="appStoreUrl" class="overlay-button" @click="trackButtonClick('APP_STORE_OPEN')">Get Niki</a>
          <p class="overlay-link" @click="openApp">Open App</p>
        </div>
      </div>
    </div>
    <div class="cta-download">
      <p>Download the Niki app to explore more!</p>
      <a :href="appStoreUrl" class="cta-button" @click="trackButtonClick('APP_STORE_OPEN')">Get Niki</a>
    </div>
  </div>
</template>

<script>
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";
import ProfileMaskBg from '../assets/ProfilePhotoMaskBG.png';
import AppIcon from '../assets/AppIconSmall.jpg';
import ListWant from '../assets/ListWant.png';
import ListFave from '../assets/ListFave.png';
import ListBeen from '../assets/ListBeen.png';
import ListDiss from '../assets/ListDiss.png';
import { event, pageview } from 'vue-gtag';
export default {
  data() {
    return {
      list: null,
      profile: null,
      places: [],
      showOverlay: false,
      hasScrolled: false,
      appStoreUrl: "https://apps.apple.com/us/app/niki-discover-and-share/id6464529554",
      deepLinkUrl: 'nikiApp://'
    };
  },
  computed: {
    thumbnailUrl() {
      if (this.list && this.list.thumbnail) {
        return this.list.thumbnail;
      } else if (this.list && this.list.list_type == "Want To Go") {
        return ListWant;
      } else if (this.list && this.list.list_type == "Favorites") {
        return ListFave;
      } else if (this.list && this.list.list_type == "Been There") {
        return ListBeen;
      } else if (this.list && this.list.list_type == "Never Again") {
        return ListDiss;
      } 
      return null;
    },
    outerContainerStyle() {
      return {
        backgroundColor: '#2C2C2C',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'  // Ensure content stacks vertically
      };
    },
    containerStyle() {
      return {
        maxWidth: '375px',
        width: '100%',
        backgroundColor: this.primaryBgLight,
        padding: '20px',
        borderRadius: '20px'
      };
    },
    headerStyle() {
      return {
        backgroundColor: this.primaryBgLight,
        color: '#DFDFDF',
        textAlign: 'left',
        padding: this.thumbnailUrl ? '20px' : '0',
        margin: '-20px',
        position: 'relative',
        height: this.thumbnailUrl ? '160px' : '20px',
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
        overflow: 'hidden' // Ensures no content spills out of the rounded corners
      };
    },
    thumbnailWrapperStyle() {
      return {
        display: this.thumbnailUrl ? 'block' : 'none'
      };
    },
    thumbnailBlurStyle() {
      return {
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px'
      };
    },
    profileMaskBgUrl() {
      return ProfileMaskBg;
    },
    appIconUrl() {
      return AppIcon;
    },
    primaryBgLight() {
      return getComputedStyle(document.documentElement).getPropertyValue('--primary-bg-light').trim() || '#FAF9F6';
    }
  },
  methods: {
    async fetchList() {
      const listId = this.$route.params.id;
      const listDocRef = doc(db, "lists", listId);
      const listDoc = await getDoc(listDocRef);
      if (listDoc.exists()) {
        this.list = listDoc.data();
        this.trackPageView(listId);
        await this.fetchProfile();
        await this.fetchPlaces();
      } else {
        console.log("No such document!");
      }
    },
    async fetchProfile() {
      if (!this.list || !this.list.user_id) return;
      const profileDocRef = doc(db, "profiles", this.list.user_id);
      const profileDoc = await getDoc(profileDocRef);
      if (profileDoc.exists()) {
        this.profile = profileDoc.data();
      } else {
        console.log("No such profile document!");
      }
    },
    async fetchPlaces() {
      if (!this.list || !this.list.location_ids) return;
      const placeIds = this.list.location_ids.slice(0, 5); // Get the first 5 IDs
      let allPlaces = [];

      for (let i = 0; i < placeIds.length; i++) {
        const placeDocRef = doc(db, "places", placeIds[i]);
        const placeDoc = await getDoc(placeDocRef);
        if (placeDoc.exists()) {
          allPlaces.push(placeDoc.data());
        }
      }

      this.places = allPlaces;
    },
    priceLevelToString(priceLevel) {
      switch (priceLevel) {
        case 1: return "$";
        case 2: return "$$";
        case 3: return "$$$";
        case 4: return "$$$$";
        default: return "";
      }
    },
    openApp() {
      this.trackButtonClick('APP_OPEN');
      if (this.list) {
        window.location.href = `nikiApp://list/${this.$route.params.id}`
      }
    },
    dismissOverlay() {
      this.showOverlay = false;
    },
    handleScroll() {
      if (!this.hasScrolled) {
        this.showOverlay = true;
        this.hasScrolled = true;
      }
    },
    trackButtonClick(buttonName) {
      event('click', {
        event_category: 'button',
        event_label: buttonName,
        value: this.list?.id
      });
    },
    trackPageView(listId) {
      pageview({
        page_path: `/list/${listId}`,
        page_title: this.list?.name || 'List Details',
        page_location: window.location.href
      });
    }
  },
  watch: {
    list(newList) {
      if (newList && newList.name) {
        document.title = newList.name;
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  async created() {
    await this.fetchList();
  },
};
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400&display=swap');

:root {
  --primary-base: #4CA88E;
  --primary-light: var(--primary-base);
  --primary-dark: var(--primary-base);
  --primary-bg-light: #FAF9F6;
  --primary-bg-dark: #2C2C2C;
  --text-fg-light: #332C18;
  --text-fg-dark: #C7C7C9;
  --stroke-light: rgba(0, 0, 0, 0.1);
  --stroke-dark: rgba(255, 255, 255, 0.1);
  --card-bg-light: #C3C3C3;
  --card-bg-dark: #1D1C26;
  --button-bg-light: var(--text-fg-light);
  --button-bg-dark: var(--text-fg-dark);
  --button-text-light: var(--primary-bg-light);
  --button-text-dark: #060613;
  --overlay-fg-light: #CBCDD3;
  --overlay-fg-dark: #CBCDD3;
  --alert-light: #993b35;
  --alert-dark: #993b35;
  --gradient-bg-light: #C7C3C8;
  --gradient-bg-dark: #060612;
  --prompt-light: #6690B9;
  --prompt-dark: #0354A6;
  --title-light: #333333;
  --title-dark: #DFDFDF;
  --subtitle-light: #9F9F9F;
  --subtitle-dark: #CFCFCF;
  --cta1-light: #6F887D;
  --cta1-dark: #6F887D;
  --cta2-light: #6F6F6F;
  --cta2-dark: #6F6F6F;
  --cta4-light: #D9D9D9;
  --cta4-dark: #D9D9D9;
  --thumbnail-label-light: #F5E6DE;
  --thumbnail-label-dark: #F5E6DE;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--primary-bg-dark);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.thumbnail-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: black;
}

.thumbnail-blur {
  position: absolute;
  top: 0;
  left: 0;
  width: 102%;
  height: 100%;
  filter: blur(15px);
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.thumbnail-center {
  position: relative;
  max-width: 150px;
  z-index: 1;
  margin: auto;
  display: block;
  align-items: center;
  top: 10%;

}

.no-thumbnail {
  background-color: black;
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 66px;
}

.profile-photo-container {
  position: relative;
  width: 58px;
  height: 64px;
  margin-right: 8px;
  object-fit: contain;
}

.profile-photo-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
}

.profile-photo-mask {
  width: 58px;
  height: 64px;
  mask-image: url(~@/assets/ProfilePhotoMaskSolid.png);
  mask-size: cover;
  mask-repeat: no-repeat;
  -webkit-mask-image: url(~@/assets/ProfilePhotoMaskSolid.png);
  -webkit-mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  z-index: 1;
  overflow: hidden;
}

.profile-photo {
  width: 54px;
  height: 60px;
  object-fit: cover;
}

.profile h2 {
  padding-bottom: 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
}

.profile h3 {
  display: block;
  color: var(--subtitle-dark);
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: 200;
  padding-bottom: 24px;
}

.profile-details {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.profile-name {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: left;
}

.profile-handle {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #777;
  text-align: left;
}

.item {
  background-color: var(--card-bg-dark);
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}

.item img {
  width: 100%;
  height: auto;
}

.item-content {
  padding: 0px;
}

.item-content h3 {
  margin: 0 0 10px;
  font-family: "Poppins", sans-serif;
  font-style: bold;
  font-size: 20px;
  text-align: left;
}

.item-content p {
  margin: 0 0 10px;
  color: var(--text-fg-dark);
}

.item-content .tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

}

.item-content .tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 0 15px;
  color: #6F6F6F;
  border-radius: 50px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: bold;
  background-color: #D9D9D9;
}

.item-content .rating {
  color: #4CA88E;
}

.divider {
  border: 0;
  height: 1px;
  background: #ddd;
  margin: 20px 0;
}

.title {
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-style: bold;
  background-color: var(--primary-light);
  color: var(--title-light);
  text-align: left;
  padding-top: 24px;
}

@media (prefers-color-scheme: light) {
  .header {
    background-color: var(--primary-light);
    color: var(--title-light);
    overflow: hidden;
  }

  .profile span {
    color: var(--subtitle-light);
  }

  .item {
    background-color: var(--card-bg-light);
  }

  .item-content p {
    color: var(--text-fg-light);
  }
}

.bottom-overlay {
  position: fixed;
  bottom: -100%;
  width: 100%;
  max-width: 360px;
  background-color: #FFFFFF;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
  transition: bottom 0.5s ease-in-out;
}

.bottom-overlay.slide-up {
  bottom: 0;
}

.overlay-content {
  text-align: center;
  padding: 4px;
}

.overlay-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 0px;
}

.overlay-profile-photo {
  width: 64px;
  height: 64px;
  border-radius: 10%;
}

.overlay-close {
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  color: #ccc;
  cursor: pointer;
  margin-top: 8px;
}

.overlay-body {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
}

.overlay-title {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.overlay-subtitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.overlay-button {
  background-color: #3897f0;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  text-decoration: none;
}

.overlay-link {
  font-size: 14px;
  color: #3897f0;
  cursor: pointer;
}

.redacted {
  background: linear-gradient(90deg, #ececec 25%, #f3f3f3 50%, #ececec 75%);
  background-size: 200% 100%;
  animation: placeholderShimmer 1.5s infinite;
}

@keyframes placeholderShimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.cta-download {
  color: #fff;
  margin-top: 20px;
  text-align: center;
}

.cta-button {
  display: inline-block;
  background-color: #3897f0;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 220px;
}
</style>