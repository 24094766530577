import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCBIcSDxaYvi8h8sWQdZ8tMOG-LrNBE3ic",
  authDomain: "niki-app-ed84c.firebaseapp.com",
  projectId: "niki-app-ed84c",
  storageBucket: "niki-app-ed84c.appspot.com",
  messagingSenderId: "495083253983",
  appId: "1:495083253983:web:96cc594f69078219f8396a",
  measurementId: "G-JGWYELMMF4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db, analytics };