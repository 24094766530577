<template>
  <div id="app" :style="appStyle">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    appStyle() {
      return {
        padding: '20px',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#2C2C2C'
      };
    },
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 100;
}
</style>
