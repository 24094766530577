// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { VueGtag } from 'vue-gtag';

const app = createApp(App)
app.use(VueGtag, {
  property: {
    id: 'G-5BRWN5JWCG',
  }
}, router);
app.use(router)
app.mount('#app');
